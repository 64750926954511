<template>
    <div
        class="relative sm:flex flex-col sm:justify-center sm:items-center min-h-screen bg-dots-darker bg-center bg-gray-100 dark:bg-dots-lighter dark:bg-gray-900 selection:bg-red-500 selection:text-white"
    >
        <h1 class="text-xl font-semibold text-gray-900 dark:text-white">
            RL Overlays
        </h1>
        <div class="p-2">
            <label class="text-white pr-2">Relay capture port:</label>
            <input class="rounded" type="number" v-model="port" />
        </div>
        <div class="max-w-7xl mx-auto p-6 lg:p-8">
            <div class="mt-16">
                <div class="grid grid-cols-1 md:grid-cols-2 gap-6 lg:gap-8">
                    <card-component :link="`/events/${port}`">
                        <template #title>Capture Events Page</template>
                        <template #body>Listening on port: {{ port }}</template>
                    </card-component>
                    <card-component :link="`/scores/0/${port}`">
                        <template #title>Team 1</template>
                        <template #body>Listening on port: {{ port }}</template>
                    </card-component>
                    <card-component :link="`/scores/1/${port}`">
                        <template #title>Team 2</template>
                        <template #body>Listening on port: {{ port }}</template>
                    </card-component>
                    <card-component :link="`/teams/${port}`">
                        <template #title>Both Teams</template>
                        <template #body>Listening on port: {{ port }}</template>
                    </card-component>
                    <card-component :link="`/overlay/teams/${port}`">
                        <template #title>OBS Overlay</template>
                        <template #body>Listening on port: {{ port }}</template>
                    </card-component>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CardComponent from "../components/CardComponent.vue";
export default {
    name: "HomePage",
    components: {
        CardComponent,
    },
    data() {
        return {
            port: 0,
        };
    },
};
</script>
