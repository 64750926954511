import { createApp, h } from "vue";
import { createInertiaApp } from "@inertiajs/vue3";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import PrimeVue from "primevue/config";
import Wind from "primevue/passthrough/tailwind";
import ToastService from "primevue/toastservice";

createInertiaApp({
    resolve: (name) => {
        const pages = import.meta.glob("./Pages/**/*.vue", { eager: true });
        return pages[`./Pages/${name}.vue`];
    },
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) }).use(plugin);

        Sentry.init({
            app,
            dsn: import.meta.env.VITE_SENTRY_DSN,
            tracesSampleRate: 0.25,
            trackComponents: true,
            attachProps: true,
            logErrors: true,
            integrations: [new BrowserTracing()],
            autoSessionTracking: true,
        });
        Sentry.setUser(props.initialPage.props.user);
        app.use(PrimeVue, {
            unstyled: true,
            pt: Wind,
        });
        app.use(ToastService);
        app.mount(el);
    },
});
