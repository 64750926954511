<template>
    <div
        class="flex flex-col justify-center items-center h-screen w-screen overflow-hidden bg-gray-900 text-gray-900 font-bold"
    >
        <div
            class="bg-auto bg-repeat opacity-5 grayscale fixed top-0 left-0 h-full w-full z-0"
            style="
                background-image: url(https://gigazonegaming.com/wp-content/themes/gigazone-gaming/images/backgrounds/social-banner-v-1.jpg);
            "
        ></div>
        <template v-if="Object.keys(team).length > 0">
            <div
                class="flex justify-center px-2 py-1 mb-3 text-6xl text-white z-10"
            >
                <p
                    class="rounded-3xl p-3 px-6 uppercase"
                    :style="{
                        backgroundColor: `#${team.color_primary}`,
                    }"
                >
                    {{ team.name }}
                </p>
                <p
                    class="rounded-full p-3 px-6 uppercase ml-2"
                    :style="{ backgroundColor: `#${team.color_primary}` }"
                >
                    {{ team.score }}
                </p>
            </div>
            <div class="flex w-full p-2 grid grid-cols-3 gap-10 z-10">
                <div
                    :key="player.id"
                    v-for="player in players"
                    class="border rounded-xl p-3"
                    :style="{
                        borderColor: `#${team.color_primary}`,
                        backgroundColor: `#${team.color_secondary}`,
                    }"
                >
                    <p
                        class="text-center text-6xl py-2"
                        :style="{ color: `#${team.color_primary}` }"
                    >
                        {{ player.name }}
                    </p>
                    <ProgressBar
                        class="text-5xl"
                        style="height: 60px"
                        :value="player.boost"
                        :pt="{
                            value: {
                                style: {
                                    backgroundColor: `#${team.color_primary}`,
                                },
                            },
                        }"
                    >
                        {{ player.boost }}
                    </ProgressBar>
                    <table class="table w-full font-bold uppercase text-5xl">
                        <tbody>
                            <tr
                                class="border-b"
                                :style="{
                                    borderColor: `#${team.color_primary}`,
                                }"
                            >
                                <td class="py-1">Score</td>
                                <td class="py-1 text-center">
                                    {{ player.score }}
                                </td>
                            </tr>
                            <tr
                                class="border-b"
                                :style="{
                                    borderColor: `#${team.color_primary}`,
                                }"
                            >
                                <td class="py-1">Goals</td>
                                <td class="py-1 text-center">
                                    {{ player.goals }}
                                </td>
                            </tr>
                            <tr
                                class="border-b"
                                :style="{
                                    borderColor: `#${team.color_primary}`,
                                }"
                            >
                                <td class="py-1">Shots</td>
                                <td class="py-1 text-center">
                                    {{ player.shots }}
                                </td>
                            </tr>
                            <tr
                                class="border-b"
                                :style="{
                                    borderColor: `#${team.color_primary}`,
                                }"
                            >
                                <td class="py-1">Assists</td>
                                <td class="py-1 text-center">
                                    {{ player.assists }}
                                </td>
                            </tr>
                            <tr
                                class="border-b"
                                :style="{
                                    borderColor: `#${team.color_primary}`,
                                }"
                            >
                                <td class="py-1">Saves</td>
                                <td class="py-1 text-center">
                                    {{ player.saves }}
                                </td>
                            </tr>
                            <tr>
                                <td class="py-1">Demos</td>
                                <td class="py-1 text-center">
                                    {{ player.demos }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <transition name="bounce">
                <div
                    v-if="update_state.game.isOT"
                    class="flex justify-center px-2 py-1 my-3 text-8xl z-50 text-red-100"
                >
                    <p class="rounded-3xl p-3 px-6 uppercase bg-red-500">
                        OVERTIME
                    </p>
                    <p class="rounded-3xl p-3 px-6 uppercase ml-2 bg-red-500">
                        +{{ update_state.game.time_seconds }}s
                    </p>
                </div>
            </transition>
        </template>
        <template v-else>
            <div class="flex justify-center px-2 py-1 mb-3 z-10">
                <p
                    class="rounded-3xl p-3 px-6 uppercase text-6xl font-bold"
                    :class="[
                        team_number === 0 ? 'bg-blue-600' : 'bg-orange-500',
                    ]"
                    :style="{ backgroundColor: `#${team.color_primary}` }"
                >
                    Waiting For Team Information
                </p>
            </div>
        </template>
        <transition name="bounce">
            <div
                v-if="
                    Object.keys(goal_scored).length > 0 &&
                    goal_scored.scorer.teamnum === team_number
                "
                class="flex absolute self-center top-1/2 text-9xl font-bold z-50 text-white"
            >
                <p
                    class="rounded-3xl p-3 px-6 uppercase p-2 whitespace-nowrap"
                    :class="[
                        team_number === 0 ? 'bg-blue-600' : 'bg-orange-500',
                    ]"
                    :style="{ backgroundColor: `#${team.color_primary}` }"
                >
                  <i class="fa-solid fa-star"></i> {{ goal_scored.scorer.name }} Scored <i class="fa-solid fa-star"></i>
                </p>
            </div>
        </transition>
        <transition name="bounce">
            <div
                v-if="
                    Object.keys(match_ended).length > 0 &&
                    match_ended.winner_team_num === team_number
                "
                class="flex absolute self-center top-1/2 text-9xl font-bold z-50"
            >
                <p
                    class="rounded-3xl p-3 px-6 uppercase text-white"
                    :class="[
                        team_number === 0 ? 'bg-blue-600' : 'bg-orange-500',
                    ]"
                    :style="{ backgroundColor: `#${team.color_primary}` }"
                >
                  <i class="fa-solid fa-trophy"></i> Winners
                </p>
            </div>
        </transition>
    </div>
</template>

<script>
import Echo from "laravel-echo";
import Socket from "socket.io-client";
import ProgressBar from "primevue/progressbar";
export default {
    name: "ScoresPage",
    components: {
        ProgressBar,
    },
    props: {
        port: {
            required: true,
        },
    },
    data() {
        return {
            team_number: this.$page.props.team_number,
            team: {},
            players: {},
            goal_scored: {},
            match_ended: {},
            update_state: {},
        };
    },
    mounted() {
        const vm = this;
        const echo = new Echo({
            broadcaster: "socket.io",
            host: window.location.origin,
            client: Socket,
        });
        echo.channel(`update_state.${vm.port}`).listen("UpdateState", (e) => {
            const data = e.data;
            vm.update_state = data;
            vm.team = data["game"]["teams"][vm.team_number];
            const players = Object.values(data["players"]);
            vm.players = players.filter(
                (player) => player.team === vm.team_number,
            );
        });
        echo.channel(`goal_scored.${vm.port}`).listen("GoalScored", (e) => {
            vm.goal_scored = e.data;
            setTimeout(() => {
                vm.goal_scored = {};
            }, 7000);
        });
        echo.channel(`match_ended.${vm.port}`).listen("MatchEnded", (e) => {
            vm.match_ended = e.data;
            setTimeout(() => {
                vm.match_ended = {};
            }, 7000);
        });
        echo.channel(`match_destroyed.${vm.port}`).listen(
            "MatchDestroyed",
            () => {
                vm.team = {};
                vm.players = {};
            },
        );
    },
};
</script>
