<template>
    <div
        class="flex flex-col items-center pt-5 h-screen w-screen text-gray-900 font-bold"
    >
        <template v-if="Object.keys(teams).length > 0">
            <div class="flex w-full justify-between">
                <div class="flex flex-col">
                    <div
                        class="flex flex-col text-white my-1 p-1 rounded"
                        :key="player.id"
                        v-for="player in players[0]"
                        style="background-color: rgba(24, 115, 255, 0.5)"
                    >
                        <p>{{ player.name }}</p>
                        <ProgressBar
                            style="height: 20px"
                            :value="player.boost"
                            :pt="{
                                value: {
                                    style: {
                                        backgroundColor: `#${teams[0].color_primary}`,
                                    },
                                },
                            }"
                        >
                            {{ player.boost }}
                        </ProgressBar>
                        <div class="flex">
                            <div class="flex items-center">
                                <img
                                    src="../../../icons/goals.webp"
                                    alt="goals"
                                    width="50px"
                                />
                                <p>
                                    {{ player.goals }}
                                </p>
                            </div>
                            <div class="flex items-center">
                                <img
                                    src="../../../icons/assists.webp"
                                    alt="assists"
                                    width="50px"
                                />
                                <p>
                                    {{ player.assists }}
                                </p>
                            </div>
                            <div class="flex items-center">
                                <img
                                    src="../../../icons/saves.webp"
                                    alt="saves"
                                    width="50px"
                                />
                                <p>
                                    {{ player.saves }}
                                </p>
                            </div>
                            <div class="flex items-center">
                                <img
                                    src="../../../icons/demos.webp"
                                    alt="demos"
                                    width="50px"
                                />
                                <p>
                                    {{ player.demos }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex flex-col">
                    <div
                        class="flex flex-col text-white my-1 p-1 rounded"
                        :key="player.id"
                        v-for="player in players[1]"
                        style="background-color: rgba(249, 115, 22, 0.5)"
                    >
                        <p class="text-right">{{ player.name }}</p>
                        <ProgressBar
                            style="height: 20px"
                            :value="player.boost"
                            :pt="{
                                value: {
                                    style: {
                                        backgroundColor: `#${teams[1].color_primary}`,
                                    },
                                },
                            }"
                        >
                            {{ player.boost }}
                        </ProgressBar>
                        <div class="flex">
                            <div class="flex items-center">
                                <img
                                    src="../../../icons/goals.webp"
                                    alt="goals"
                                    width="50px"
                                />
                                <p>
                                    {{ player.goals }}
                                </p>
                            </div>
                            <div class="flex items-center">
                                <img
                                    src="../../../icons/assists.webp"
                                    alt="assists"
                                    width="50px"
                                />
                                <p>
                                    {{ player.assists }}
                                </p>
                            </div>
                            <div class="flex items-center">
                                <img
                                    src="../../../icons/saves.webp"
                                    alt="saves"
                                    width="50px"
                                />
                                <p>
                                    {{ player.saves }}
                                </p>
                            </div>
                            <div class="flex items-center">
                                <img
                                    src="../../../icons/demos.webp"
                                    alt="demos"
                                    width="50px"
                                />
                                <p>
                                    {{ player.demos }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="flex justify-center px-2 py-1 mb-3">
                <p class="bg-orange-500 rounded-3xl p-3 px-6 uppercase text-6xl font-bold">
                    Waiting For Team Information
                </p>
            </div>
        </template>
    </div>
</template>

<script>
import Echo from "laravel-echo";
import Socket from "socket.io-client";
import ProgressBar from "primevue/progressbar";
export default {
    name: "ScoresOverlay",
    components: {
        ProgressBar,
    },
    props: {
        port: {
            required: true,
        },
    },
    data() {
        return {
            team_number: this.$page.props.team_number,
            teams: [],
            players: [],
            update_state: {},
        };
    },
    mounted() {
        const vm = this;
        const echo = new Echo({
            broadcaster: "socket.io",
            host: window.location.origin,
            client: Socket,
        });
        echo.channel(`update_state.${vm.port}`).listen("UpdateState", (e) => {
            const data = e.data;
            vm.update_state = data;
            vm.teams = data["game"]["teams"];
            const players = Object.values(data["players"]);
            vm.teams.forEach((team, id) => {
                vm.players[id] = players.filter((player) => player.team === id);
            });
        });
        echo.channel(`match_destroyed.${vm.port}`).listen(
            "MatchDestroyed",
            () => {
                vm.teams = [];
                vm.players = [];
            },
        );
    },
};
</script>
