<template>
    <div class="flex justify-between">
        <div class="flex-initial opacity-50"><i class="fa-solid fa-arrow-left"></i></div>
        <div class="flex-initial">{{ label }}</div>
        <div class="flex-initial opacity-50"><i class="fa-solid fa-arrow-right"></i></div>
    </div>
</template>

<script>
export default {
    name: "AllScoresLabelComponent",
    props: {
        label: {
            type: String,
            required: true,
        },
    },
};
</script>

<style scoped>
h1 {
    color: #42b983;
}
</style>
