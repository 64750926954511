<template>
    <div
        class="flex flex-col items-center justify-center h-screen w-screen bg-gray-900 text-gray-900 font-bold"
    >
        <div
            class="bg-auto bg-repeat opacity-5 grayscale absolute top-0 left-0 h-full w-full z-0"
            style="background-image: url(https://gigazonegaming.com/wp-content/themes/gigazone-gaming/images/backgrounds/social-banner-v-1.jpg);"
        ></div>
        <template v-if="Object.keys(teams).length > 0">
            <div class="flex p-2 grid grid-cols-3 gap-10 z-10">
                <team-component :team="teams[0]" :players="players[0]" />
                <div>
                    <div
                        class="flex justify-center px-2 py-1 mb-3 text-6xl items-center"
                    >
                        <p
                            class="rounded-full p-3 px-6 uppercase text-6xl font-bold mr-2 text-white"
                            :style="{
                                backgroundColor: `#${teams[0].color_primary}`,
                            }"
                        >
                            {{ teams[0].score }}
                        </p>
                        <p class="text-white text-center p-2">-</p>
                        <p
                            class="rounded-full p-3 px-6 uppercase text-6xl font-bold ml-2 text-white"
                            :style="{
                                backgroundColor: `#${teams[1].color_primary}`,
                            }"
                        >
                            {{ teams[1].score }}
                        </p>
                    </div>
                    <div class="border border-neutral-50/[.06] rounded-xl p-3 bg-neutral-50/[.06]">
                        <table
                            class="table w-full font-bold uppercase text-3xl text-center text-white"
                        >
                            <tbody>
                                <tr class="border-b border-gray-800">
                                    <td class="py-4">
                                        <all-scores-label label="name" />
                                      </td>
                                </tr>
                                <tr class="border-b border-gray-800">
                                    <td class="py-4"><all-scores-label label="boost" /></td>
                                </tr>
                                <tr class="border-b border-gray-800">
                                    <td class="py-4"><all-scores-label label="score" /></td>
                                </tr>
                                <tr class="border-b border-gray-800">
                                    <td class="py-4"><all-scores-label label="goals" /></td>
                                </tr>
                                <tr class="border-b border-gray-800">
                                    <td class="py-4"><all-scores-label label="shots" /></td>
                                </tr>
                                <tr class="border-b border-gray-800">
                                    <td class="py-4"><all-scores-label label="Assists" /></td>
                                </tr>
                                <tr class="border-b border-gray-800">
                                    <td class="py-4"><all-scores-label label="Saves" /></td>
                                </tr>
                                <tr class="border-b border-gray-800">
                                    <td class="py-4"><all-scores-label label="Demos" /></td>
                                </tr>
                                <tr class="border-b border-gray-800">
                                    <td class="py-4"><all-scores-label label="Touches" /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <team-component :team="teams[1]" :players="players[1]" />
            </div>
        </template>
        <template v-else>
            <div class="flex justify-center px-2 py-1 mb-3 z-10">
                <p class="bg-orange-500 rounded-3xl p-3 px-6 uppercase text-6xl font-bold">
                    Waiting For Team Information
                </p>
            </div>
        </template>
        <transition name="bounce">
            <div
                v-if="Object.keys(goal_scored).length > 0"
                class="flex absolute self-center top-1/2 z-50"
            >
                <p
                    class="rounded-3xl p-3 px-6 uppercase text-9xl font-bold text-white whitespace-nowrap"
                    :class="[
                        goal_scored.scorer.teamnum === 0
                            ? 'bg-blue-600'
                            : 'bg-orange-500',
                    ]"
                    :style="{
                        backgroundColor: `#${
                            teams[goal_scored.scorer.teamnum].color_primary
                        }`,
                    }"
                >
                  <i class="fa-solid fa-star"></i> {{ goal_scored.scorer.name }} Scored <i class="fa-solid fa-star"></i>
                </p>
            </div>
        </transition>
        <transition name="bounce">
            <div
                v-if="Object.keys(match_ended).length > 0"
                class="flex absolute self-center top-1/2 z-50"
            >
                <p
                    class="rounded-3xl p-3 px-6 uppercase text-9xl font-bold text-white whitespace-nowrap"
                    :class="[
                        match_ended.winner_team_num === 0
                            ? 'bg-blue-600'
                            : 'bg-orange-500',
                    ]"
                    :style="{
                        backgroundColor: `#${
                            teams[match_ended.winner_team_num].color_primary
                        }`,
                    }"
                >
                  <i class="fa-solid fa-trophy"></i>  {{ teams[match_ended.winner_team_num].name }} WIN!
                </p>
            </div>
        </transition>
    </div>
</template>

<script>
import Echo from "laravel-echo";
import Socket from "socket.io-client";
import TeamComponent from "../components/TeamComponent.vue";
import AllScoresLabel from "../components/AllScoresLabelComponent.vue";
export default {
    name: "AllScoresPage",
    components: {
        TeamComponent,
        AllScoresLabel,
    },
    props: {
        port: {
            required: true,
        },
    },
    data() {
        return {
            team_number: this.$page.props.team_number,
            teams: [],
            players: [],
            goal_scored: {},
            match_ended: {},
            update_state: {},
        };
    },
    mounted() {
        const vm = this;
        const echo = new Echo({
            broadcaster: "socket.io",
            host: window.location.origin,
            client: Socket,
        });
        echo.channel(`update_state.${vm.port}`).listen("UpdateState", (e) => {
            const data = e.data;
            vm.update_state = data;
            vm.teams = data["game"]["teams"];
            const players = Object.values(data["players"]);
            vm.teams.forEach((team, id) => {
                vm.players[id] = players.filter((player) => player.team === id);
            });
        });
        echo.channel(`goal_scored.${vm.port}`).listen("GoalScored", (e) => {
            vm.goal_scored = e.data;
            setTimeout(() => {
                vm.goal_scored = {};
            }, 7000);
        });
        echo.channel(`match_ended.${vm.port}`).listen("MatchEnded", (e) => {
            vm.match_ended = e.data;
            setTimeout(() => {
                vm.match_ended = {};
            }, 7000);
        });
        echo.channel(`match_destroyed.${vm.port}`).listen(
            "MatchDestroyed",
            () => {
                vm.teams = [];
                vm.players = [];
            },
        );
    },
};
</script>
