<template>
    <div>
        <div class="flex justify-center px-2 py-1 mb-3 text-6xl">
            <p
                class="rounded-3xl p-3 px-6 uppercase text-white"
                :style="{
                    backgroundColor: `#${team.color_primary}`,
                }"
            >
                {{ team.name }}
            </p>
        </div>
        <div
            class="border rounded-xl p-3"
            :style="{
                borderColor: `#${team.color_primary}`,
                backgroundColor: `#${team.color_secondary}`,
            }"
        >
            <div class="flex grid gap-1" :class="'grid-cols-' + players.length">
                <table
                    :key="player.id"
                    v-for="player in players"
                    class="table w-full font-bold uppercase text-3xl text-center"
                >
                    <tbody>
                        <tr
                            class="border-b"
                            :style="{
                                borderColor: `#${team.color_primary}`,
                            }"
                        >
                            <td class="py-4">{{ player.name }}</td>
                        </tr>
                        <tr
                            class="border-b"
                            :style="{
                                borderColor: `#${team.color_primary}`,
                            }"
                        >
                            <td class="py-4">
                                <ProgressBar
                                    style="height: 40px"
                                    :value="player.boost"
                                    :pt="{
                                        value: {
                                            style: {
                                                backgroundColor: `#${team.color_primary}`,
                                            },
                                        },
                                    }"
                                >
                                    {{ player.boost }}
                                </ProgressBar>
                            </td>
                        </tr>
                        <tr
                            class="border-b"
                            :style="{
                                borderColor: `#${team.color_primary}`,
                            }"
                        >
                            <td class="py-4">{{ player.score }}</td>
                        </tr>
                        <tr
                            class="border-b"
                            :style="{
                                borderColor: `#${team.color_primary}`,
                            }"
                        >
                            <td class="py-4">{{ player.goals }}</td>
                        </tr>
                        <tr
                            class="border-b"
                            :style="{
                                borderColor: `#${team.color_primary}`,
                            }"
                        >
                            <td class="py-4">{{ player.shots }}</td>
                        </tr>
                        <tr
                            class="border-b"
                            :style="{
                                borderColor: `#${team.color_primary}`,
                            }"
                        >
                            <td class="py-4">{{ player.assists }}</td>
                        </tr>
                        <tr
                            class="border-b"
                            :style="{
                                borderColor: `#${team.color_primary}`,
                            }"
                        >
                            <td class="py-4">{{ player.saves }}</td>
                        </tr>
                        <tr
                            class="border-b"
                            :style="{
                                borderColor: `#${team.color_primary}`,
                            }"
                        >
                            <td class="py-4">{{ player.demos }}</td>
                        </tr>
                        <tr
                            class="border-b"
                            :style="{
                                borderColor: `#${team.color_primary}`,
                            }"
                        >
                            <td class="py-4">{{ player.touches }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import ProgressBar from "primevue/progressbar";
export default {
    name: "TeamComponent",
    components: {
        ProgressBar,
    },
    props: {
        team: {
            required: true,
        },
        players: {
            required: true,
        },
    },
};
</script>
