<template>
    <div class="flex flex-col h-screen bg-gray-900">
        <h1
            class="font-bold text-3xl text-center text-white"
            :class="[connected ? 'bg-green-600' : 'bg-red-600']"
        >
            {{
                connected
                    ? "Connected"
                    : shouldConnect
                      ? "Connecting"
                      : "Not Connected"
            }}
        </h1>
        <div v-if="!connected" class="flex justify-end m-2">
            <button
                v-if="shouldConnect"
                key="stop-connecting"
                class="text-white py-1 px-2 rounded bg-red-600"
                @click="stopConnecting()"
            >
                Stop Connecting
            </button>
            <button
                v-else
                key="start-connecting"
                class="text-white py-1 px-2 rounded bg-green-600"
                @click="connect()"
            >
                Connect
            </button>
        </div>
        <div
            class="flex grow overflow-hidden justify-center pb-5 text-gray-300"
        >
            <ul
                class="bg-gray-800 w-3/4 overflow-y-scroll border border-double"
            >
                <li
                    :key="value"
                    v-for="(event, value) in events"
                    class="p-2"
                    :class="[value % 2 ? 'bg-gray-700' : '']"
                >
                    {{ Object.keys(event)[0] }} :
                    <button
                        @click="activeEvent = event"
                        class="text-white px-2 rounded bg-blue-600"
                    >
                        event
                    </button>
                </li>
            </ul>
        </div>
        <div
            class="flex flex-col absolute w-full h-screen"
            v-if="Object.keys(activeEvent).length > 0"
        >
            <button @click="activeEvent = {}" class="text-white bg-red-600">
                Close
            </button>
            <JsonEditorVue
                class="jse-theme-dark h-full"
                v-model="activeEvent"
                :readOnly="true"
            />
        </div>
    </div>
</template>

<script>
import { WsSubscribers } from "../ws_subscribers";
import axios from "axios";
import "vanilla-jsoneditor/themes/jse-theme-dark.css";
import JsonEditorVue from "json-editor-vue";
export default {
    name: "EventsPage",
    components: {
        JsonEditorVue,
    },
    props: {
        port: {
            required: true,
        },
    },
    data() {
        return {
            connected: false,
            shouldConnect: true,
            connecting: undefined,
            wsSubscribers: {},
            activeEvent: {},
            events: [],
        };
    },
    mounted() {
        const vm = this;
        this.connect();
        WsSubscribers.subscribe("ws", "error", () => {
            vm.connected = false;
        });
        WsSubscribers.subscribe("ws", "open", () => {
            vm.connected = true;
            vm.stopConnecting();
            vm.subscribe();
        });
    },
    methods: {
        connect() {
            const vm = this;
            this.shouldConnect = true;
            this.connecting = setInterval(() => {
                WsSubscribers.init(vm.port);
            }, 1000);
        },
        stopConnecting() {
            this.shouldConnect = false;
            clearInterval(this.connecting);
        },
        subscribe() {
            const vm = this;
            WsSubscribers.subscribe("game", "match_created", (event) => {
                vm.events.push({ match_created: event });
                axios
                    .post("/api/event/game/match_created", {
                        event: event,
                        port: vm.port,
                    })
                    .catch((e) => {
                        console.log(e.response.data);
                    });
            });

            WsSubscribers.subscribe("game", "initialized", (event) => {
                vm.events.push({ initialized: event });
                axios
                    .post("/api/event/game/initialized", {
                        event: event,
                        port: vm.port,
                    })
                    .catch((e) => {
                        console.log(e.response.data);
                    });
            });

            WsSubscribers.subscribe("game", "pre_countdown_begin", (event) => {
                vm.events.push({ pre_countdown_begin: event });
                axios
                    .post("/api/event/game/pre_countdown_begin", {
                        event: event,
                        port: vm.port,
                    })
                    .catch((e) => {
                        console.log(e.response.data);
                    });
            });

            WsSubscribers.subscribe("game", "post_countdown_begin", (event) => {
                vm.events.push({ post_countdown_begin: event });
                axios
                    .post("/api/event/game/post_countdown_begin", {
                        event: event,
                        port: vm.port,
                    })
                    .catch((e) => {
                        console.log(e.response.data);
                    });
            });

            WsSubscribers.subscribe("game", "update_state", (event) => {
                vm.events.push({ update_state: event });
                axios
                    .post("/api/event/game/update_state", {
                        event: event,
                        port: vm.port,
                    })
                    .catch((e) => {
                        console.log(e.response.data);
                    });
            });

            WsSubscribers.subscribe("game", "ball_hit", (event) => {
                vm.events.push({ ball_hit: event });
                axios
                    .post("/api/event/game/ball_hit", {
                        event: event,
                        port: vm.port,
                    })
                    .catch((e) => {
                        console.log(e.response.data);
                    });
            });

            WsSubscribers.subscribe("game", "statfeed_event", (event) => {
                vm.events.push({ statfeed_event: event });
                axios
                    .post("/api/event/game/statfeed_event", {
                        event: event,
                        port: vm.port,
                    })
                    .catch((e) => {
                        console.log(e.response.data);
                    });
            });

            WsSubscribers.subscribe("game", "goal_scored", (event) => {
                vm.events.push({ goal_scored: event });
                axios
                    .post("/api/event/game/goal_scored", {
                        event: event,
                        port: vm.port,
                    })
                    .catch((e) => {
                        console.log(e.response.data);
                    });
            });

            WsSubscribers.subscribe("game", "replay_start", (event) => {
                vm.events.push({ replay_start: event });
                axios
                    .post("/api/event/game/replay_start", {
                        event: event,
                        port: vm.port,
                    })
                    .catch((e) => {
                        console.log(e.response.data);
                    });
            });

            WsSubscribers.subscribe("game", "replay_will_end", (event) => {
                vm.events.push({ replay_will_end: event });
                axios
                    .post("/api/event/game/replay_will_end", {
                        event: event,
                        port: vm.port,
                    })
                    .catch((e) => {
                        console.log(e.response.data);
                    });
            });

            WsSubscribers.subscribe("game", "replay_end", (event) => {
                vm.events.push({ replay_end: event });
                axios
                    .post("/api/event/game/replay_end", {
                        event: event,
                        port: vm.port,
                    })
                    .catch((e) => {
                        console.log(e.response.data);
                    });
            });

            WsSubscribers.subscribe("game", "match_ended", (event) => {
                vm.events.push({ match_ended: event });
                axios
                    .post("/api/event/game/match_ended", {
                        event: event,
                        port: vm.port,
                    })
                    .catch((e) => {
                        console.log(e.response.data);
                    });
            });

            WsSubscribers.subscribe("game", "podium_start", (event) => {
                vm.events.push({ podium_start: event });
                axios
                    .post("/api/event/game/podium_start", {
                        event: event,
                        port: vm.port,
                    })
                    .catch((e) => {
                        console.log(e.response.data);
                    });
            });

            WsSubscribers.subscribe("game", "match_destroyed", (event) => {
                vm.events.push({ match_destroyed: event });
                axios
                    .post("/api/event/game/match_destroyed", {
                        event: event,
                        port: vm.port,
                    })
                    .catch((e) => {
                        console.log(e.response.data);
                    });
            });
        },
    },
};
</script>
